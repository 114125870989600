import { Pagination, Table } from "antd";
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react";

import { useFetch } from "../../utils/hooks/useFetch";
import { Filters } from "./components/Filters";
import { TableTitle } from "./components/TableTitle";
// import { NestedTable } from "./components/NestedTable";
import { reportsService } from "../../services/reports";
import styles from "./Reports.module.css";
import moment from "moment";
import {
  baseColumnTemplate,
  offerwallAdditionalColumns,
} from "./utils/columnsList";
import { openWarningNotification } from "../../utils/notifications";
import { numberWithSpaces } from "../../utils/abbreviateNumber";
import UserContext from "../../contexts/userContext";

export const CoinisReports = ({ location }) => {
  const { userInfo } = useContext(UserContext);

  const [tableColumns, setTableColumns] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const pageSizeOptions = [10, 20, 30, 50, 100];

  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [sorting, setSorting] = useState({
    revenue: "descend",
  });
  const [isArchiveIncluded, setIsArchiveIncluded] = useState(false);

  const columnsData = useMemo(() => {
    return userInfo?.permissions?.offerwall
      ? [...offerwallAdditionalColumns, ...baseColumnTemplate]
      : baseColumnTemplate;
  }, [userInfo]);

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      if (sumOfData[0]) {
        let sumOfDataRefactored = { ...sumOfData[0] };
        if (sumOfData[0].revenue) {
          sumOfDataRefactored.revenue =
            "$ " +
            Number(sumOfData[0].revenue).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        }
        if (sumOfData[0].cr) {
          sumOfDataRefactored.cr =
            parseFloat(sumOfData[0].cr).toFixed(2) + " %";
        }
        if (sumOfData[0].cpm) {
          sumOfDataRefactored.cpm =
            "$ " +
            Number(sumOfData[0].cpm).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        }
        if (sumOfData[0].ecpa) {
          sumOfDataRefactored.ecpa =
            "$ " +
            Number(sumOfData[0].ecpa).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        }
        if (sumOfData[0].rpl) {
          sumOfDataRefactored.rpl =
            "$ " +
            Number(sumOfData[0].rpl).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        }
        if (sumOfData[0].rpm) {
          sumOfDataRefactored.rpm =
            "$ " +
            Number(sumOfData[0].rpm).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        }
        return sumOfDataRefactored;
      }
    }
  };

  const refactorTableData = () => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
        const refactor = { ...el };
        refactor.on_hold_revenue =
          refactor.on_hold_revenue &&
          "$ " +
          Number(refactor.on_hold_revenue).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.confirmed_revenue =
          refactor.confirmed_revenue &&
          "$ " +
          Number(refactor.confirmed_revenue).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.declined_revenue =
          refactor.declined_revenue &&
          "$ " +
          Number(refactor.declined_revenue).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.revenue =
          refactor.revenue &&
          "$ " +
          Number(refactor.revenue).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.rpl =
          refactor.rpl &&
          "$ " +
          Number(refactor.rpl).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.rpm =
          refactor.rpm &&
          "$ " +
          Number(refactor.rpm).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.cpm =
          refactor.cpm &&
          "$ " +
          Number(refactor.cpm).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.ecpa =
          refactor.ecpa &&
          "$ " +
          Number(refactor.ecpa).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        refactor.cr = refactor.cr && refactor.cr.toFixed(2) + " %";
        refactor.roi = refactor.roi && refactor.roi.toFixed(2) + " %";
        refactor.date = moment(refactor.date, "YYYYMMDD").format(
          "YYYY-MM-DD"
        );
        refactor.hour = moment(refactor.hour, "H").format("HH:mm");
        refactor.im = numberWithSpaces(refactor.im);
        refactor.leads = numberWithSpaces(refactor.leads);
        return {
          key: index,
          ...refactor,
        };
      })
      : "";
    return tableDataRefactored;
  };

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting]
  );

  const updateColumns = (value, columnsData) => {
    const newTableColumns = columnsData
      .filter((column) => value.checkedColumns[column["value"]])
      .map(function ({ text, value, tooltip, render }) {
        return {
          title: (
            <TableTitle
              text={text}
              order={sorting[value] ? sorting[value] : false}
              value={value}
              tooltip={tooltip}
              onSortingClick={handleSortingClick}
            />
          ),
          dataIndex: value,
          render,
        }
      });
    setTableColumns(newTableColumns);
  };

  const loadZoneData = (cancelToken) => {
    return reportsService.getStatsPaged({
      selectedFilters,
      isArchiveIncluded,
      currentPage,
      pageSize,
      sorting,
      cancelToken,
      action: false,
    });
  };

  const [
    {
      data: { total: sumOfData, table: tableData, rows: totalItems },
      isFetching,
    },
    getZoneData,
  ] = useFetch(loadZoneData);
  const loading = isFetching;

  useEffect(() => setCurrentPage(1), []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getZoneData();
    }
  }, [sorting, pageSize, selectedFilters, getZoneData, currentPage]);

  useEffect(() => {
    const selectedColumns = tableColumns.map((item) => item.dataIndex);
    if (
      selectedColumns.includes("date") &&
      selectedColumns.includes("revenue")
    ) {
      setSorting({ date: "descend", revenue: "descend" });
    } else {
      selectedColumns.includes("date")
        ? setSorting({ date: "descend" })
        : setSorting({ revenue: "descend" });
    }
  }, [tableColumns]);

  const handleExport = () => {
    if (selectedFilters.filters) {
      reportsService.exportReports({
        selectedFilters,
        isArchiveIncluded,
        currentPage,
        pageSize,
        sorting,
        action: false,
      });
    } else {
      openWarningNotification({
        message: "Please update filters before trying to export table.",
      });
    }
  };

  return (
    <div>
      <h2 className={styles.pageTitle}>Statistics</h2>
      <Filters
        campaign_id={location.campaign_id}
        channel={location.channel}
        handleExport={handleExport}
        columnsData={columnsData}
        onSubmit={(value, columnsData) => {
          updateColumns(value, columnsData);
          setSelectedFilters(value);
        }}
        allColumns={columnsData}
        isOfferwall={userInfo?.permissions?.offerwall}
        isAffiliate={userInfo?.permissions?.affiliate_programs}
        dateRange={dateRange}
        setDateRange={setDateRange}
        isArchiveIncluded={isArchiveIncluded}
        setIsArchiveIncluded={setIsArchiveIncluded}
      />
      <Table
        className={styles.mainTableContainer}
        style={{ marginTop: "2%" }}
        columns={tableColumns}
        rowKey={({ key }) => key}
        dataSource={refactorTableData()}
        loading={loading}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
        pagination={false}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={(value) => setCurrentPage(value)}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(curr, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
              showTotal={(total) => {
                if (total)
                  return (
                    <div>
                      Showing {(currentPage - 1) * pageSize + 1} to{" "}
                      {total < currentPage * pageSize
                        ? total
                        : currentPage * pageSize}{" "}
                      of {total} entries.
                    </div>
                  );
              }}
            />
          );
        }}
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor();
          const sum =
            sumOfData && sumOfData[0] ? (
              <Table.Summary.Row>
                {tableColumns.map((el, index) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {numberWithSpaces(sumOfDataRefactored[el.dataIndex])}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            ) : null;
          return sum;
        }}
      />
    </div>
  );
};
